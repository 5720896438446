import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Callout from '../components/callout';
import PostImage from '../components/postimage';

const StyleGuidePage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle} menuLinks={data.site.siteMetadata.menuLinks}>
      <h1>Style Guide</h1>
      <p>This my style guide.</p>
      <h2>Tokens</h2>
      <h3>Colors</h3>
      <h3>Main Colors</h3>
      <ul class="tag-list">
        <li class="background-primary">Primary Color: #BEFF8A</li>
        <li class="background-accent">Accent Color: #F5EF7E</li>
        <li class="background-danger">Danger Color: #FF0150</li>
      </ul>
      <h3>Text Colors</h3>
      <ul>
        <li>Text Color: #FDFDFD</li>
      </ul>

      <h4>Background Colors</h4>
      <ul class="tag-list">
        <li class="background-bglight">Background Light Color: #353B30</li>
        <li class="background">Background Color: #0F140B</li>
      </ul>

      <h2>Callouts</h2>
      <Callout variant="info">Info Callout</Callout>
      <Callout variant="warning">Warning Callout</Callout>
      <Callout variant="danger">Danger Callout</Callout>

      <h2>Posts</h2>
      <p>Every post has a default image like this.</p>
      <PostImage/>
    </Layout>
  )
}

export const Head = () => <Seo title="Style Guide" />

export default StyleGuidePage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
